<template>
  <div id="terms-and-conditions">
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5">
        <div class="mt-5 mb-5 text-center">
          <b-img width="100px" :src="require('../../assets/images/logo/logo.png')"/>
        </div>
        <p class="text-gray-light"><i>{{ $t('Last Updated: April 29th, 2021') }}</i></p>
        <div class="mt-5">
          <h3 class="section-header-title text-primary-dark d-flex text-upper">{{ $t('Terms and Conditions') }}</h3>
          <p class="mt-3">{{ $t('BY USING OR ACCESSING OUR WEBSITE OR DOWNLOADING MATERIALS FROM OUR WEBSITE YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS OF USE.') }}</p>
          <p>{{ $t('This Terms of Use Agreement (the “Agreement”) is a legal agreement between you and WYA/ World Yoga Alliance® or “WYA Community” / WYA Registry (collectively referred to herein as “WYA,” “we” or “us”) governing your use of this website,') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('or any other website linked to this Agreement (the “WYA Website”). Please note that by accessing, viewing, using, or downloading materials from the Website, you agree to be legally bound by all of the terms, conditions and notices contained or referenced in this Agreement.') }}</p>
          <!-- <p class="article-content mt-3">{{ $t('These terms and conditions outline the rules and regulations for the use of World Yoga Alliance’s Website, located at www.wyayoga.org and www.wyayoga.com') }}</p> -->
          <p class="article-content mt-3">{{ $t('By accessing these websites, we assume you accept these terms and conditions. Do not continue to use our Official Websites if you do not agree to take all of the terms and conditions stated on this page.') }}</p>
          <p class="article-content mt-3">{{ $t('The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Member”, “You” and “Your” or “User” refers to you, the person who logs onto this website and is compliant to the WYA Organisations terms and conditions. “The Organization”, “Ourselves”, “We”, “Our” and “Us”, refers to our Organization. “Party”, “Parties”, or “Us”, refers to both the Member and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Member in the most appropriate manner for the express purpose of meeting the member’s needs in respect of provision of the World Yoga Alliance’s stated services, in accordance with and subject to, prevailing law of Thailand and India. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.') }}</p>
        </div>
        <div class="table-of-contents mt-5">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('Table of Contents') }}</h3>
          <ol>
            <li @click="scrollToElement('website-terms-of-use')">{{ $t('Website Terms of Use:') }}</li>
            <ol>
              <li @click="scrollToElement('new-members')">{{ $t('New Members:') }}</li>
              <li @click="scrollToElement('user-submitted-information-and-grant-of-license')">{{ $t('User-Submitted Information and Grant of License:') }}</li>
              <li @click="scrollToElement('license')">{{ $t('License:') }}</li>
              <li @click="scrollToElement('account-creation')">{{ $t('Account Creation:') }}</li>
              <li @click="scrollToElement('user-conduct')">{{ $t('User Conduct:') }}</li>
              <li @click="scrollToElement('unauthorized-use-or-access')">{{ $t('Unauthorized Use or Access:') }}</li>
              <li @click="scrollToElement('validity')">{{ $t('Validity:') }}</li>
              <li @click="scrollToElement('community-forums')">{{ $t('Community Forums:') }}</li>
              <li @click="scrollToElement('comments')">{{ $t('Comments:') }}</li>
              <li @click="scrollToElement('monitoring')">{{ $t('Monitoring:') }}</li>
              <li @click="scrollToElement('social-media-accounts')">{{ $t('Social Media Accounts:') }}</li>
              <li @click="scrollToElement('promotions-and-campaign')">{{ $t('Promotions and Campaign:') }}</li>
            </ol>
            <li @click="scrollToElement('copyright')">{{ $t('Our Intellectual Property Rights "Copyright"') }}</li>
            <ol>
              <li @click="scrollToElement('trademarks')">{{ $t('Trademarks:') }}</li>
              <li @click="scrollToElement('notice-of-copyright-infringement')">{{ $t('Notice of Copyright Infringement:') }}</li>
              <li @click="scrollToElement('other-wya-policies-and-terms')">{{ $t('Other WYA Policies and Terms:') }}</li>
            </ol>
            <li @click="scrollToElement('links-to-third-party-websites')">{{ $t('Links to Third-Party Websites:') }}</li>
            <ol>
              <li @click="scrollToElement('third-party-content')">{{ $t('Third-Party Content:') }}</li>
              <li @click="scrollToElement('hyperlinking-to-our-content')">{{ $t('Hyperlinking to Our Content:') }}</li>
              <li @click="scrollToElement('website-security')">{{ $t('Website Security:') }}</li>
              <li @click="scrollToElement('removal-of-links-from-our-website')">{{ $t('Removal of links from our website:') }}</li>
              <li @click="scrollToElement('cookie')">{{ $t('Cookie:') }}</li>
            </ol>
            <li @click="scrollToElement('termination')">{{ $t('Termination:') }}</li>
            <ol>
              <li @click="scrollToElement('limitation-of-liability')">{{ $t('Limitation of Liability:') }}</li>
              <li @click="scrollToElement('changes')">{{ $t('Changes:') }}</li>
              <li @click="scrollToElement('physical-well-being')">{{ $t('Physical well Being') }}</li>
              <li @click="scrollToElement('children')">{{ $t('Children:') }}</li>
              <li @click="scrollToElement('sexual-harassment')">{{ $t('Sexual Harassment:') }}</li>
            </ol>
            <li @click="scrollToElement('reservation-of-rights')">{{ $t('Reservation of Rights:') }}</li>
            <ol>
              <li @click="scrollToElement('jurisdictional-issues')">{{ $t('Jurisdictional Issues:') }}</li>
              <li @click="scrollToElement('effect-of-invalidity')">{{ $t('Effect of Invalidity:') }}</li>
              <li @click="scrollToElement('iframes')">{{ $t('Iframes:') }}</li>
              <li @click="scrollToElement('disclaimer')">{{ $t('Disclaimer:') }}</li>
              <li @click="scrollToElement('contact-us')">{{ $t('Contact us:') }}</li>
            </ol>
          </ol>
        </div>
         <div id="website-terms-of-use" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1. <u>{{ $t('Website Terms of Use:') }}</u></p></h3>
          <p class="article-content mt-3">{{ $t('We may revise the information on the Website or otherwise change or update the Website, including this Agreement. Please check the “Last Updated” legend at the top of this page to see when this Agreement was last revised. Changes will become effective immediately. A current version of this Agreement showing the effective date is always available at this location. We encourage you to periodically review this Agreement to see if there have been any changes to our policies that may affect you. World Yoga Alliance® (WYA) may also make improvements and/or changes to this Website, add new features, or terminate this Website at any time without notice. If you do not agree to the Agreement as modified, then you must discontinue your use of the Website. Your continued use of this Website will signify your continued agreement to this Agreement as it may be revised.') }}</p>
        </div>
        <div id="new-members" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.1 <u>{{ $t('New Members:') }}</u></p></h3>
          <p class="article-content mt-3">{{ $t('By applying for any new memberships, you confirm that all of your information and documents given are 100% true and not falsified in any way, shape or manor. You acknowledge that providing any false or misleading information will result in immediate cancellation of your membership with no refund. this rule adheres to any membership with us including but not limited to RSY (Register School of Yoga), RTY (Register Teacher of Yoga), CYS (Certified Yoga School) and CYT (Certified Yoga Teacher) or any WYA Designation & Trademarks.') }}</p>
          <ul>
          <li>{{ $t('CYT and CYS have more leverage and abilities to use and include WYA certification as well as logos compare to RSY and RTY Standards.') }}</li>
          </ul>
        </div>
        <div id="user-submitted-information-and-grant-of-license" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.2 <u>{{ $t('User-Submitted Information and Grant of License:') }}</u></p></h3>
          <p class="article-content">{{ $t('You must exercise caution, good sense and sound judgment in using this Website. You are responsible for any material and information (“Content”) you transmit to us through the Website (including through email via WYA Website and in any Forums). You agree, represent and warrant that any Content you transmit to us through the Website (including through email via the WYA Websites) is truthful, accurate, not misleading and offered in good faith, and that you have the right to transmit such Content. Certain Content collected from you on the WYA Website is subject to our Privacy Policy.') }}</p>
          <p>{{ $t('World Yoga Alliance® (WYA) does not want you to, and you should not, send any confidential or proprietary Content to us through this Website unless specifically requested. Please note that any unsolicited Content sent to us will be deemed not to be confidential or proprietary.') }}</p>
          <p>{{ $t('By submitting Content, other than personally identifiable information, through this Website, you grant to World Yoga Alliance (or warrant that the owner of such information and material has expressly granted to WYA) a royalty-free, perpetual, irrevocable, and unrestricted right and license (a) to use, reproduce, display, modify, adapt, publish, translate, transmit and distribute or otherwise make available to others such Content (in whole or in part and for any purpose) worldwide and/or to incorporate it in other works in any form, media, or technology now known or hereafter developed; (b) to exercise all copyright, trademark, publicity, privacy and other proprietary rights with regard to such Content; and (c) to use your name, or user name, hometown, photograph, portrait, picture and biographical information as news or information in any and all media and/or communications, whether or not in connection with your Content. You also hereby grant each user of the WYA Website a non-exclusive license to access your Content through the Website, and to tag, rate, review, comment on, use, reproduce, distribute, display and perform such Content as permitted through the functionality of the Website and under this Agreement. You also agree that World Yoga Alliance (WYA) is free to use any ideas, concepts, know-how or techniques that you send to us for any purpose. Please do not send us any ideas, suggestions or other useful content through this website that you wish us to keep confidential or for which you expect to receive compensation.') }}</p>
          <p>{{ $t('You shall not upload, post or otherwise make available on or through this Website (including in any Forum) any Content that (a) infringes upon or violates the rights of any third party including any copyright, trademark, trade secret, or other intellectual property rights, rights of publicity, rights of privacy, or contract rights; (b) is illegal, defamatory, obscene, pornographic, vulgar, indecent, lewd, offensive, threatening, abusive, harmful, inflammatory, deceptive, false, misleading, or fraudulent; (c) promotes hatred, discrimination, bigotry, racism, harassment, violence or harm against any individual or group; (d) violates, or encourages any conduct that would violate, any applicable laws, rules or regulations or give rise to any civil liability; (e) contains any viruses, corrupted data or other harmful, disruptive or destructive files; (f) restricts, interferes with or inhibits any other person from using or enjoying Descript; or (g) that would otherwise expose us or any third party to liability, special regulations, or harm of any kind.. You shall be solely liable for any damages resulting from any violation of the foregoing rules or any other harm resulting from such a submission. Posting Content in violation of these rules may result in the removal of such Content and the suspension or termination of your right to use of the WYA Website.') }}</p>
        </div>
        <div id="license" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.3 <u>{{ $t('License:') }}</u></p></h3>
          <p>{{ $t('Unless otherwise stated, World Yoga Alliance® and/or its licensors own the intellectual property rights for all material according to their membership or registration with WYA on this website')  }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('All intellectual property rights are reserved. You may access this you’re member profile') }}  <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('for your own personal use subjected to restrictions set in these terms and conditions.') }}</p>
          <p>{{ $t('World Yoga Alliance® (WYA) does not want you to, and you should not, send any confidential or proprietary Content to us through this Website unless specifically requested. Please note that any unsolicited Content sent to us will be deemed not to be confidential or proprietary.') }}</p>
          <p>{{ $t('By submitting Content, other than personally identifiable information, through this Website, you grant to World Yoga Alliance (or warrant that the owner of such information and material has expressly granted to WYA) a royalty-free, perpetual, irrevocable, and unrestricted right and license (a) to use, reproduce, display, modify, adapt, publish, translate, transmit and distribute or otherwise make available to others such Content (in whole or in part and for any purpose) worldwide and/or to incorporate it in other works in any form, media, or technology now known or hereafter developed; (b) to exercise all copyright, trademark, publicity, privacy and other proprietary rights with regard to such Content; and (c) to use your name, or user name, hometown, photograph, portrait, picture and biographical information as news or information in any and all media and/or communications, whether or not in connection with your Content. You also hereby grant each user of the WYA Website a non-exclusive license to access your Content through the Website, and to tag, rate, review, comment on, use, reproduce, distribute, display and perform such Content as permitted through the functionality of the Website and under this Agreement. You also agree that World Yoga Alliance (WYA) is free to use any ideas, concepts, know-how or techniques that you send to us for any purpose. Please do not send us any ideas, suggestions or other useful content through this website that you wish us to keep confidential or for which you expect to receive compensation.') }}</p>
          <p>{{ $t('You shall not upload, post or otherwise make available on or through this Website (including in any Forum) any Content that (a) infringes upon or violates the rights of any third party including any copyright, trademark, trade secret, or other intellectual property rights, rights of publicity, rights of privacy, or contract rights; (b) is illegal, defamatory, obscene, pornographic, vulgar, indecent, lewd, offensive, threatening, abusive, harmful, inflammatory, deceptive, false, misleading, or fraudulent; (c) promotes hatred, discrimination, bigotry, racism, harassment, violence or harm against any individual or group; (d) violates, or encourages any conduct that would violate, any applicable laws, rules or regulations or give rise to any civil liability; (e) contains any viruses, corrupted data or other harmful, disruptive or destructive files; (f) restricts, interferes with or inhibits any other person from using or enjoying Descript; or (g) that would otherwise expose us or any third party to liability, special regulations, or harm of any kind.. You shall be solely liable for any damages resulting from any violation of the foregoing rules or any other harm resulting from such a submission. Posting Content in violation of these rules may result in the removal of such Content and the suspension or termination of your right to use of the WYA Website.') }}</p>
          <p>{{ $t('You must not:') }}</p>
          <ul>
            <li>{{ $t('Republish material this includes but is not limited to written words, video content, photos, voice recordings, PDF files, online books, WYA syllabus or anything else from') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a></li>
            <li>{{ $t('Sell, rent or sub-license material or content from World Yoga Alliance.') }}</li>
            <li>{{ $t('Reproduce, duplicate or copy material this includes but is not limited to: CYT, RTY, CYS, RSY or other WYA licenses from') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a></li>
            <li>{{ $t('Redistribute content from') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a></li>
            <li>{{ $t('Modify, change or edit our official logo, licenses or any official documents for your own use.') }}</li>
          </ul>
          <p>{{ $t('License agreement for CYS (Certified Yoga School) and Division worldwide:')}} </p>
          <ul>
            <li>{{ $t('Upon training completion, it is the school responsibility to submit CYT (Certified Yoga Teacher) registration and the registration fee for all of their graduates to World Yoga Alliance®. All use of WYA Diploma is subject to') }} <router-link :to="{ name: 'CYS' }" class="text-danger-light"><u>{{ $t('CYS Policy Terms and Condition.') }}</u></router-link></li>
            <li>{{ $t('World Yoga Alliance® has no policy to force or request graduates/students to pay any additional charge for CYT Registration License out of the training fee. Our CYS is a responsible for it.') }}</li>
          </ul>
        </div>
        <div id="account-creation" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.4 <u>{{ $t('Account Creation:') }}</u></p></h3>
          <p>{{ $t('You are not obligated to create an account to access WYA Website. However, certain sections and features of our website are available only to visitors to') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('who have created an account, by providing us with their email address, and creating a password (“Secure Users”). If you are a Secure User, you agree to accurately maintain and update any information about yourself that you have provided to us. You further agree that you are responsible for all activities that occur under your Secure User account or password. You are responsible for maintaining the confidentiality of your password and for restricting access to your computer so others may not access the Website in violation of this Agreement. You may only have one active Secure User account on WYA Website at any given time and only you may use your designated account to access the') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('profile or your personal account. You also agree to promptly notify us of any unauthorized use of your account or any other breach of security that you become aware of involving or relating to this Website by emailing us at') }} <a class="" href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ $t('In addition, you agree to exit from your Secure User account at the end of each session. We explicitly disclaim liability for any and all losses and damages arising from your failure to comply with this section.') }}</p>
        </div>
        <div id="user-conduct" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.5 <u>{{ $t('User Conduct:') }}</u></p></h3>
          <p>{{ $t('You are solely responsible for your conduct when using the WYA Website and any Forums or “Community Forums”, and you agree not to do any of the following: (a) impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity; (b) stalk, intimidate, threaten, or otherwise harass or cause discomfort to other users; (c) send distribute or post spam, unsolicited or bulk commercial electronic communications, chain letters, or pyramid schemes; (d) harvest or otherwise collect or disclose information about other users without their consent; (e) use the WYA Website or the Forums for any illegal or unauthorized purpose or engage in, encourage, or promote any illegal activity, or any activity that violates these Terms; (f) circumvent or attempt to circumvent any filtering, security measures or similar features that protect the WYA Website, our users and World Yoga Alliance; (g) use any data mining, robots or similar data gathering or extraction methods; and (h) distribute or enable any malware, spyware, adware or other malicious code. You acknowledge and agree that we are not liable in any manner for the conduct of other users. Violation of these user conduct rules may result in the suspension or termination of your right to use of the WYA Website.') }}</p>
        </div>
        <div id="unauthorized-use-or-access" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.6 <u>{{ $t('Unauthorized Use or Access:') }}</u></p></h3>
          <p>{{ $t('Unless otherwise expressly authorized in this Agreement or on the Website, you may not take any action to interfere with the Website or any other user\'s use of the Website. While using the Website you are required to comply with all applicable statutes, orders, regulations, rules, and other laws. In addition, we expect users of the Website to respect the rights and dignity of others. Your use of the WYA Website is conditioned on your compliance with the rules of conduct set forth in this section. You may not (and you expressly agree that you will not) do any of the following, which violate this Agreement:') }}</p>
          <ul>
            <li>{{ $t('Interfere with or disrupt the operation of the WYA Website or others’ use of the Website in any way (including without limitation by hacking or defacing any portion of the Website) ') }}</li>
            <li>{{ $t('Use the Website for any fraudulent or unlawful purpose.') }}</li>
            <li>{{ $t('Reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Website.') }}</li>
            <li>{{ $t('Modify, adapt, translate, reverse engineer, decompile or disassemble any portion of the WYA Website.') }}</li>
            <li>{{ $t('Post, upload, share, transmit, distribute, facilitate distribution or otherwise make available to or through this Website any unlawful, infringing, harmful, harassing, defamatory, threatening, intimidating, fraudulent, tortious, vulgar or otherwise objectionable material of any kind, including unauthorized or unsolicited advertising;') }}</li>
            <li>{{ $t('Use the WYA Website to defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others, including without limitation others\' privacy rights or rights of publicity, or harvest or collect personally identifiable information about users of the WYA Website.') }}</li>
            <li>{{ $t('Impersonate any person or entity, including without limitation any representative of WYA, falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the Website; or express or imply that we endorse any statement you make.') }}</li>
            <li>{{ $t('Violate any applicable laws or regulations; or Assist or permit any persons in engaging in any of the activities described above.') }}</li>
            <li>{{ $t('Disseminate on the WYA Website any viruses, worms, spyware, adware, or other malicious computer code, file or program that is harmful or invasive or may or is intended to damage or hijack the operation of, or monitor the use of, any hardware, software or equipment.') }}</li>
          </ul>
        </div>
        <div id="validity" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.7 <u>{{ $t('Validity:') }}</u></p></h3>
          <p>{{ $t('Membership will be valid for lifetime and annual. However, failure to comply with our terms and conditions or standards will result in immediate termination of your membership. Please read more about Termination in section 4;') }}</p>
        </div>
        <div id="community-forums" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.8 <u>{{ $t('Community Forums:') }}</u></p></h3>
          <p>{{ $t('We may host message community center boards, chat rooms, WYA member groups, blogs, videos, exclusive center and other interactive forums or services (each, a “Forum”) on the WYA Website. Forums are intended to serve as discussion centers. Any user failing to comply with this agreement may be expelled from and refused continued access to forums in the future. You understand that our staff, our outside contributors, or other users connected with World Yoga Alliance Community may participate in forums or other aspects of the WYA Website and may employ anonymous user names when doing so. YOU ACKNOWLEDGE AND AGREE that forums are public spaces and that your participation in such forums creates no expectation of privacy. Further, you acknowledge that any personal information you communicate in forums may be seen and used by others. We are not responsible for information that you or others choose to communicate in forums, or for your actions or the actions of other users.') }}</p>
          <p><u>{{ $t('Please Note') }}</u>: {{ $t('World Yoga Alliance® reserves the right to monitor all comments and to remove any comments which can be considered inappropriate, offensive or causes breach of these terms and conditions. We highly recommend you to give comments that only follow the ethical yogic way.') }}</p>
        </div>
        <div id="comments" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.9 <u>{{ $t('Comments:') }}</u></p></h3>
          <p>{{ $t('This agreement terms and condition are saved with World Yoga Alliance® official team.') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. World Yoga Alliance® (WYA) does not filter, publish or review comments prior to their presence on the website, However we have rights to remove the comments if goes against our community standards. Comments do not reflect the views and opinions of World Yoga Alliance, its agents and/or affiliates. comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, World Yoga Alliance® shall not be liable for the comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the comments on') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a></p>
          <p>{{ $t('If you choose to make any of your personally Identifiable or other information publicly available in a forum or otherwise on or through the website, YOU DO SO AT YOUR OWN RISK. In addition to these Terms, your use of forums may be subject to certain rules and guidelines, such as the') }} <u>{{ $t('WYA Community Rules & Etiquette Guidelines') }}</u>, {{ $t('which you agree to by accessing or using such forum. World Yoga Alliance or its designated agents may remove, refuse to post, or alter any information or content posted or otherwise disclosed in any forum at any time for any reason.') }}</p>
          <p><u>{{ $t('Please Note') }}</u>: {{ $t('World Yoga Alliance® reserves the right to review, edit, publish, remove or monitor all the “Trainings and Events” and all of the “Community Forums” that You post or publish in our Website. We highly recommend you to publish your events that only follow the ethical yogic path with truthfulness.') }}</p>
          <p>{{ $t('You warrant and represent that:') }}</p>
          <ul>
            <li>{{ $t('You are entitled to post the comments on our website and have all necessary licenses and consents to do so;') }}</li>
            <li>{{ $t('The comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;') }}</li>
            <li>{{ $t('The comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy') }}</li>
            <li>{{ $t('The comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity. ') }}</li>
          </ul>
          <p>{{ $t('You hereby grant World Yoga Alliance® a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your comments in any and all forms, formats or media.') }}</p>
        </div>
        <div id="monitoring" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.10 <u>{{ $t('Monitoring:') }}</u></p></h3>
          <p>{{ $t('You acknowledge and agree that we reserve the right (but have no obligation) to do one or all of the following, at our sole discretion: (a) evaluate Content before allowing it to be posted on the Website or any Forum; (b) monitor content; (c) alter, remove, reject, or refuse to post or allow to be posted, without notice to you, any Content, for any reason or for no reason whatsoever; provided, however, that we shall have no obligation or liability to you for failure to do so or for doing so in any particular manner; and/or (d) disclose any Content, and the circumstances surrounding its transmission, to any third party in order to operate the WYA Website; to protect World Yoga Alliance Community and the Website\'s users and visitors; to comply with legal obligations or governmental requests; to enforce this agreement; or for any other reason or purpose.') }}</p>
        </div>
        <div id="social-media-accounts" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.11 <u>{{ $t('Social Media Accounts:') }}</u></p></h3>
          <p>{{ $t('We may maintain a presence on several social media websites, including facebook.com, linkedin.com, YouTube.com, instagram.com, and twitter.com, (collectively, “Social Media Pages”) to provide a place for people to learn more about World Yoga Alliance and to share experiences. All comments, visuals and other materials posted by visitors to our Social Media Pages do not necessarily reflect the opinions or ideas of World Yoga Alliance. All visitors to our Social Media Pages must comply with the respective social media website’s Terms of Use. We review all postings to our Social Media Channels, and we will remove any postings that we have determined are inappropriate or offensive.') }}</p>
        </div>
        <div id="promotions-and-campaign" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>1.11 <u>{{ $t('Promotions and Campaign:') }}</u></p></h3>
          <p>{{ $t('The Promotions or promotion campaign and the agreement contracts with our members are valid as terms of service. Any sweepstakes, contests, or similar promotions made available through the Website will be governed by specific rules that are separate from this Agreement. You agree to read the applicable rules, which are linked from the particular activity, and to review our Privacy Policy which, in addition to this Agreement, governs any information you submit in connection with such activities.') }}</p>
        </div>
        <div id="copyright" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>2. <u>{{ $t('Our Intellectual Property Rights "Copyright"') }}</u></p></h3>
          <p>{{ $t('WYA Website Content on') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('is protected by International Copyright conventions and no Content from this Website may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without written permission from the WYA Office, you may use Content purposely made available by us for downloading from this Website, provided for our members only for use accordingly their registration type. Do not copy or post such information on any networked computer or broadcast it in any media. You agree not to circumvent, disable, or otherwise interfere with security-related features of this Website or features that prevent or restrict use or copying of any Content. Any unauthorized use or modification of any of the Content available on this Website is a violation of the copyrights and other proprietary rights of WYA, or other copyright owners where so indicated. You agree to cooperation in NOT publishing, modifying, duplicating, reproduce, rent, lease, loan, sell, or distribute without permission on all or any part of the WYA Website or this Website Content. If evidence found of copyright infringement, please notify') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
        </div>
        <div id="trademarks" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>2.1 <u>{{ $t('Trademarks:') }}</u></p></h3>
          <p>{{ $t('All designation logos as trademarks/trade names, service marks including product names, product packaging and designs at WYA Online "Yoga Store" Community services of World Yoga Alliance Website at') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('including or belong exclusively to us or their respective owners and are protected under national and international trademark and copyright laws. All use of World Yoga Alliance trademarks is subject to') }} <router-link :to="{ name: 'designationAndTrademark' }"><span class="text-danger-light">WYA <u>{{ $t('Designation & Trademark') }}</u></span></router-link> {{ $t('Guidelines.') }}</p>
        </div>
        <div id="notice-of-copyright-infringement" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>2.2 <u>{{ $t('Notice of Copyright Infringement:') }}</u></p></h3>
          <p>{{ $t('If you are confident that any Content on WYA Website') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('infringes upon any copyright which you own or control, you may send a written notification to our Administrative manager (The "Designated Copyright Officer") as set forth below. Designated Copyright Officer: ADMINISTRATIVE MANAGER') }}</p>
          <p>{{ $t('Email:') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
          <p>{{ $t('If you believe that your copyrighted work is available on the Website in violation of your copyright, you may provide our Designated Copyright Officer with a written notice which contains substantially the following information:') }}</p>
          <ul>
            <li>{{ $t('Identify the URL or other specific location on the WYA Website that contains the alleged infringing material described in Item (a) above. You must provide us with reasonably sufficient information to enable us to locate the alleged infringing material.') }}</li>
            <li>{{ $t('Identify in sufficient detail the copyrighted work that you claim has been infringed so that we can locate the alleged infringing material. If multiple copyrighted works are covered by your notification, you may provide a representative list of such works.') }}</li>
            <li>{{ $t('Provide the electronic or physical signature of the owner of the copyright or a person authorized to act on the owner’s behalf.') }}</li>
            <li>{{ $t('Include a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.') }}</li>
            <li>{{ $t('Include a statement by you that the information contained in your notice is accurate and that you attest under penalty of perjury that you are the copyright owner or that you are authorized to act on the copyright owner’s behalf.') }}</li>
            <li>{{ $t('Include your name, mailing address, telephone number and email address.') }}</li>
            <li>{{ $t('If any user of the WYA Website is deemed to be a repeat copyright infringer, we will terminate such user’s license to use the WYA Website and Services.') }}</li>
          </ul>
        </div>
        <div id="other-wya-policies-and-terms" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>2.3 <u>{{ $t('Other WYA Policies and Terms:') }}</u></p></h3>
          <p>{{ $t('This Agreement applies exclusively to your access to, and use of, the Website and does not alter in any way the terms or conditions of any other agreement you may have with World Yoga Alliance. Please refer to and review all additional specific terms and conditions as applicable, including our Privacy Policy. ') }}</p>
        </div>
        <div id="links-to-third-party-websites" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>3. <u>{{ $t('Links to Third-Party Websites:') }}</u></p></h3>
          <p>{{ $t('WYA may provide on this Website') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('solely as a convenience to users, links to other websites operated by third parties or our members. If you decide to visit any linked website, you are agreed to do at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements including 3rd party websites content, data, information, photo, videos, products or resources. World Yoga Alliance "WYA" do not make any warranty or representation regarding, or endorse or otherwise sponsor, any linked websites or the information appearing thereon or any of the products or services described thereon.') }}</p>
        </div>
        <div id="third-party-content" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>3.1 <u>{{ $t('Third-Party Content:') }}</u></p></h3>
          <p>{{ $t('Any information, statements, opinions or other information provided by third parties / members profile at') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('and made available on this Website are those of the respective author(s) and not World Yoga Alliance. We do not guarantee the validity, accuracy, truthfulness, completeness, reliability or usefulness of any opinion, advice, service, offer, statement or other Content on the Website other than those from an authorized WYA representative acting in his/her official capacity. Under no circumstance will be liable for or in connection with any loss or damage caused by your reliance on any Content.') }}</p>
        </div>
        <div id="hyperlinking-to-our-content" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>3.2 <u>{{ $t('Hyperlinking to our Content:') }}</u></p></h3>
          <p>{{ $t('World Yoga Alliance® (WYA) has all rights reserved to use all data and hyperlinks associated with our registered and certified members as schools/teachers and students. If one is not register or certified member, they do not have our permission to use our link or any of our content.') }}</p>
          <p>{{ $t('The following organizations may link to') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('without prior written approval:') }}</p>
          <ul>
          <li>{{ $t('Government agencies;') }}</li>
          <li>{{ $t('Search engines;') }}</li>
          <li>{{ $t('News organizations;') }}</li>
          <li>{{ $t('Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses.') }}</li>
          </ul>
          <p>{{ $t('These organizations may link to our home page, to publications or to another Website information so long as the link:') }}</p>
          <p>{{ $t('(a) is not in any way deceptive;') }}</p>
          <p>{{ $t('(b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and') }}</p>
          <p>{{ $t('(c) fits within the context of the linking party’s site.') }}</p>
          <p>{{ $t('We may consider and approve other link requests from the following types of organizations at our own discretion:') }}</p>
          <ul>
            <li>{{ $t('commonly-known consumer and/or business information sources;') }}</li>
            <li>{{ $t('dot.com / dot.org community sites;') }}</li>
            <li>{{ $t('associations or other groups representing charities;') }}</li>
            <li>{{ $t('online directory distributors;') }}</li>
            <li>{{ $t('internet portals;') }}</li>
            <li>{{ $t('internet portals;') }}</li>
            <li>{{ $t('educational institutions and trade associations.') }}</li>
          </ul>
          <p>{{ $t('We will approve link requests from these organizations if we decide that:') }}</p>
          <p>{{ $t('(a) the link would not make us look unfavourably to ourselves or to our accredited businesses;') }}</p>
          <p>{{ $t('(b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of World Yoga Alliance®; and (d) the link is in the context of general resource information.') }}</p>
          <p>{{ $t('These organizations may link to our home page so long as the link:') }}</p>
          <p>{{ $t('(a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.') }}</p>
          <p>{{ $t('If you are one of the organizations listed in paragraph two above and are interested in linking to our website, you must inform us by sending an e-mail to WYA Admin Team at') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ ('Please wait minimum 2 -7 working days for response.') }}</p>
          <p>{{ $t('Approved organizations may hyperlink to our Website as follows:') }}</p>
          <ul>
            <li>{{ $t('By use of our corporate name; or') }}</li>
            <li>{{ $t('By use of the uniform resource locator being linked to; or') }}</li>
            <li>{{ $t('By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.') }}</li>
          </ul>
          <p>{{ $t('No use of Company Name’s logo or other artwork will be allowed for linking absent a trademark license agreement.') }}</p>
        </div>
        <div id="website-security" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>3.3 <u>{{ $t('Website Security:') }}</u></p></h3>
          <p>{{ $t('You are prohibited from violating, or attempting to violate, the security of WYA Website. Any such violations may result in criminal and/or civil penalties against you. We will investigate any alleged or suspected violations and if a criminal violation is suspected, we will cooperate with law enforcement agencies in their investigations. ') }}</p>
        </div>
        <div id="removal-of-links-from-our-website" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>3.4 <u>{{ $t('Removal of links from our website:') }}</u></p></h3>
          <p>{{ $t('If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.') }}</p>
          <p>{{ $t('We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.') }}</p>
        </div>
        <div id="cookie" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>3.5 <u>{{ $t('Cookie:') }}</u></p></h3>
          <p>{{ $t('We employ the use of cookies. By accessing WYA Websites') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('or you agreed to use cookies in agreement with World Yoga Alliance’s Privacy Policy. Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our members/affiliates or advertising partners may also use cookies.') }}</p>
        </div>
        <div id="termination" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>4. <u>{{ $t('Termination:') }}</u></p></h3>
          <p>{{ $t('WYA reserves the right to randomly check on your activities for compliance of our standard policies. We may contact you in case we are notified of something that requires our attention and clarification. Please be aware that if any inappropriate actions or violations against out regulations and standards are found, we have all rights to Terminate your membership and remove a member’s data from our registry. You must understand that WYA "World Yoga Alliance" has full rights to revoke your privileges in case of failure to uphold the standards set forth in the') }} <u>{{ $t('WYA Policies') }}</u> {{ $t('and') }} <u>{{ $t('Code of Conducts.') }}</u> {{ $t('and We may suspend, limit or terminate all or a portion of your access to this Website or any of its features at any time with or without notice and with or without cause, including, without limitation.') }}</p>
          <p>{{ $t('Possible actions which could lead to cancellation of your membership. (not exhaustive)') }}</p>
          <ul>
            <li>{{ $t('Not fully comply with WYA terms, regulations, requirements.') }}</li>
            <li>{{ $t('Misrepresenting World Yoga Alliance® which causes negative image, reputational damages or discredit to the organization.') }}</li>
            <li>{{ $t('Reproducing, modifying or infringing any parts of WYA copyrighted works/properties (including logos, trademarks, registration licenses, certificates, training materials) without approval.') }}</li>
            <li>{{ $t('Abusive behaviours towards WYA official team or members.') }}</li>
          </ul>
        </div>
        <div id="limitation-of-liability" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>4.1 <u>{{ $t('Limitation of Liability:') }}</u></p></h3>
          <p>{{ $t('World Yoga Alliance® (WYA) including each of its affiliates and divisions shall not be liable for any direct, indirect, incidental, consequential, special or exemplary damages arising out of or in any way related to the use of the service. (Even if we have been advised of the possibility of such damages.)') }}</p>
        </div>
        <div id="changes" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>4.2 <u>{{ $t('Changes:') }}</u></p></h3>
          <p>{{ $t('World Yoga Alliance® reserves the right, at our sole discretion, to change, modify, add or remove portions of these Terms and Conditions at any time by posting the amended terms and conditions. ') }}</p>
        </div>
        <div id="physical-well-being" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>4.3 <u>{{ $t('Physical well-being:') }}</u></p></h3>
          <p>{{ $t('If you get injured from any online workshop or videos that you acquire through WYA Websites') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('we are not liable for the damage caused. We always recommend taking as many safety precautions as possible whilst practicing yoga, if you decided of your own volition to practice yoga without taking safety precautions then that choice was yours and yours only. We have no responsibility for the risks you decide to take on your own.') }}</p>
        </div>
        <div id="children" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>4.4 <u>{{ $t('Children:') }}</u></p></h3>
          <p>{{ $t('Yoga is for everyone and for all ages but this Website') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('is not intended for children under the age of 13. If you are under 13 years of age, please do not use or access this Website at any time or in any manner. By using this Website, you affirm that you are over the age of 13. World Yoga Alliance / WYA Community does not seek through this Website to gather personal information from or about persons under the age of 13.') }}</p>
        </div>
        <div id="sexual-harassment" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>4.5 <u>{{ $t('Sexual Harassment:') }}</u></p></h3>
          <p>{{ $t('World Yoga Alliance® is not responsible for any sexual harassment. WYA is committed to providing a safe environment for all its members free from discrimination on any ground and from harassment including sexual harassment. Our goal as an organization is to ensure the safety and comfortability of our students, teachers, employees of our certified schools and anyone who is involved in our organization. However, World Yoga Alliance® is not responsible for the cases which may occur at any specific WYA Registered and ‘or’ Certified Yoga School or Teacher. We hereby renounce any legal responsibility in case of alleged sexual harassment complaints and recommend you to contact the school directly to deal with any further actions necessary in your case.') }}</p>
        </div>
        <div id="reservation-of-rights" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>5. <u>{{ $t('Reservation of Rights:') }}</u></p></h3>
          <p>{{ $t('We reserve the right to request that you remove all links or any particular link to') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('you agree to be bound to and follow these linking terms and conditions.') }}</p>
        </div>
        <div id="jurisdictional-issues" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>5.1 <u>{{ $t('Jurisdictional Issues:') }}</u></p></h3>
          <p>{{ $t('The Website is controlled and operated by World Yoga Alliance® from our Indian and Thailand offices and with servers located in Singapore. We make no representation that materials in the Website are appropriate or available for use in other locations. Those who choose to access the Website from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Access to the Website') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('from jurisdictions where the contents of the Website are illegal or penalized is prohibited.') }}</p>
        </div>
        <div id="effect-of-invalidity" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>5.2 <u>{{ $t('Effect of Invalidity:') }}</u></p></h3>
          <p>{{ $t('In the event a court or other authority having jurisdiction finds any portion of this Agreement unenforceable, that portion shall not be effective and the remainder of the Agreement shall remain effective.') }}</p>
        </div>
        <div id="iframes" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>5.3 <u>{{ $t('Iframes:') }}</u></p></h3>
          <p>{{ $t('Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.') }}</p>
        </div>
        <div id="disclaimer" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>5.3 <u>{{ $t('Disclaimer:') }}</u></p></h3>
          <p>{{ $t('World Yoga Alliance® and each of its affiliates, divisions, schools, students and members will not be liable for any damages of any kind arising out of or relating to the use or the inability to use') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('its content, links, or any other content associated with this website. This includes but is not limited to damages caused by or related to errors, omissions, interruptions, delay in operation, any computer virus or line failure.') }}</p>
          <p>{{ $t('The above stated will not be liable for any indirect, special, incidental, exemplary or consequential damages, even if we have been advised of the possibility of such damages. This included physical injury of any kind. we shall not have a liability or responsibility for any acts, omissions or conduct of any user or other third party. WORLD YOGA ALLIANCE "WYA" DOES NOT WARRANT OR Make any representations regarding the use, accuracy, reliability of the content available on the website.') }}</p>
          <p>{{ $t('As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature. ') }}</p>
        </div>
        <div id="contact-us" class="mt-5">
          <h3 class="text-primary-dark d-flex"><p>5.3 <u>{{ $t('Contact Us:') }}</u></p></h3>
          <p>{{ $t('If you have any questions about this Agreement, please do not hesitate to') }} <router-link :to="{ name: 'contact'} "><u>{{ $t('contact us.') }}</u></router-link></p>
          <ul>
            <li>{{ $t('World Yoga Alliance® all rights reserved since 2011.') }}</li>
          </ul>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TermsAndContions',
  title: ' | Terms and Contions',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    scrollToElement (id) {
      const element = document.getElementById(id)
      console.log('#id', id)
      const elementOffset = element.offsetTop - 80
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #terms-and-conditions{
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .table-of-contents li:hover {
    cursor:pointer;
  }
</style>
